import { LoggingService } from "./services/logging.service";
import { SharedModule } from "./modules/shared/shared.module";
import { MatNativeDateModule } from "@angular/material/core";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { environment } from "src/environments/environment";
import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireAnalyticsModule } from "@angular/fire/analytics";
import { Network } from "@ionic-native/network/ngx";
import { QuillModule } from "ngx-quill";
import { PopoverSpeechListPageModule } from "./pages/popover-speech-list/popover-speech-list.module";
import { CalendarModule } from "angular-calendar";
import { DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { AngularFireFunctionsModule, REGION } from "@angular/fire/functions";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import {
  DeleteSpeechDialog,
  UpdateSpeechDialog,
} from "./pages/speech-library/speech-library.page";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDatepickerModule } from "@angular/material/datepicker";
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";
import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { HelpModalComponent } from "./components/help-modal/help-modal.component";
import { MatInputModule } from "@angular/material/input";
import { ReactiveFormsModule } from "@angular/forms";

@NgModule({
  declarations: [
    AppComponent,
    DeleteSpeechDialog,
    HelpModalComponent,
    UpdateSpeechDialog,
  ],
  entryComponents: [DeleteSpeechDialog, HelpModalComponent, UpdateSpeechDialog],
  imports: [
    BrowserModule,
    IonicModule.forRoot({ animated: true }),
    AppRoutingModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    AngularFireAnalyticsModule,
    PopoverSpeechListPageModule,
    MatNativeDateModule,
    QuillModule.forRoot(),
    SharedModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    MatInputModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      defaultLanguage: "en",
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Network,
    { provide: REGION, useValue: "europe-west1" },
    LoggingService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

// Sentry.init({
//   dsn: "https://3d6f528014e547cc83e269d97bdc8be4@o433431.ingest.sentry.io/5765730",
//   integrations: [
//     new Integrations.BrowserTracing({
//       tracingOrigins: ["localhost", "https://yourserver.io/api"],
//       routingInstrumentation: Sentry.routingInstrumentation,
//     }),
//   ],

//   tracesSampleRate: 1.0,
// });

enableProdMode();
// platformBrowserDynamic()
//   .bootstrapModule(AppModule)
//   .then((success) => console.log(`Bootstrap success`))
//   .catch((err) => console.error(err));
