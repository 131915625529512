import { Injectable } from "@angular/core";
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from "@angular/fire/database";
import { UserProfile } from "../interfaces/user-profile";
import { DataService } from "./data.service";
import {
  FreeTrialClass,
  SubscriptionService,
  SubscriptionStatus,
  SubscriptionType,
  UserSubscription,
} from "./subscription.service";

@Injectable({
  providedIn: "root",
})
export class UserService {
  userRef: AngularFireObject<UserProfile>;

  constructor(
    private db: AngularFireDatabase,
    private data: DataService,
    private subscriptionService: SubscriptionService
  ) {}

  updateProfile(userProfile: UserProfile, isNew: boolean = false) {
    if (userProfile.displayName) {
      userProfile.profileIconLetter = userProfile.displayName.charAt(0);
    } else {
      userProfile.profileIconLetter = userProfile.email.charAt(0);
    }

    let updates = {};

    Object.getOwnPropertyNames(userProfile).forEach((field) => {
      updates["users/" + userProfile.id + "/profile/" + field] =
        userProfile[field];
    });

    if (isNew) {
      updates["lastEditedSpeech/" + userProfile.id] = { id: 0 };

      // create entry in subscription table
      let subscription = new UserSubscription(
        userProfile.id,
        SubscriptionType.basic,
        userProfile.createDate,
        SubscriptionStatus.freeTrialActive,
        FreeTrialClass.D180
      );

      this.subscriptionService.createSubscription(subscription);
    }

    return this.db.object("/").update(updates);
    // return this.db.database.ref().update(updates);
  }

  setProfilePictureVisibility(value: boolean) {
    let updates = {};
    updates["users/" + this.data.getUid() + "/profile/profilePictureDisabled"] =
      value;
    this.db.object("/").update(updates);
  }

  setDisplayName(displayName: string) {
    let updates = {};
    updates["users/" + this.data.getUid() + "/profile/displayName"] =
      displayName;

    return this.db.object("/").update(updates);
  }

  setFields(updateValues: Map<string, any>) {
    let updates = {};
    updateValues.forEach((value: string, key: string) => {
      updates["users/" + this.data.getUid() + "/profile/" + key] = value;
    });

    return this.db.object("/").update(updates);
  }

  loadProfile(uid: string): AngularFireObject<UserProfile> {
    this.userRef = this.db.object("users/" + uid + "/profile");
    return this.userRef;
  }
}
