import { FormGroup, FormControl } from "@angular/forms";
import { SpeechService } from "./../../services/speech.service";
import { ModalController, Platform } from "@ionic/angular";
import { Component, HostListener, Input, OnInit } from "@angular/core";
import { SpeechDatastoreService } from "src/app/services/speech-datastore.service";
import { EditorService } from "src/app/services/editor.service";

@Component({
  selector: "app-popup-editor",
  templateUrl: "./popup-editor.page.html",
  styleUrls: ["./popup-editor.page.scss"],
})
export class PopupEditorPage implements OnInit {
  @Input() field: string;
  @Input() id: string;
  public Editor = this.editor.editor;
  public form: FormGroup = new FormGroup({
    input: new FormControl(),
  });
  private unregisterBackAction;
  constructor(
    private modalController: ModalController,
    private sds: SpeechDatastoreService,
    private platform: Platform,
    public editor: EditorService
  ) {}

  ngOnInit() {
    const modalState = {
      modal: true,
      desc: "fake state for our modal",
    };
    history.pushState(modalState, null);
  }

  ngOnDestroy() {
    if (window.history.state.modal) {
      history.back();
    }
  }

  ionViewWillEnter() {
    this.form.setControl(
      "input",
      new FormControl(this.sds.instantSpeechData[this.field])
    );
  }

  @HostListener("window:popstate", ["$event"])
  closeEditor() {
    // this.ss.updateDb(this, "", this.field, "input");
    this.sds.updateDbData(this.id, this.field, this.form.value["input"]);
    this.modalController.dismiss();
  }
}
