import { Injectable } from "@angular/core";
import { LoadingController, ToastController } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class UiService {
  public loaderOn: boolean = false;
  private loaderIsLoading: boolean = false;
  constructor(
    public toastController: ToastController,
    public loadingController: LoadingController
  ) {}

  async showToast(text: string, duration: number = 2000) {
    const toast = await this.toastController.create({
      message: text,
      duration: duration,
    });
    toast.present();
  }

  hideToast() {
    this.toastController.dismiss();
  }

  async presentLoading(text: string) {
    this.loaderIsLoading = true;
    const loading = await this.loadingController.create({
      cssClass: "my-custom-class",
      message: text,
    });
    loading.present().then(() => {
      this.loaderIsLoading = false;
      if (!this.loaderOn) {
        setTimeout(() => {
          this.loadingController.dismiss();
        }, 100);
      }
    });
  }

  async hideLoading() {
    if (this.loaderIsLoading) {
      this.loaderOn = false;
    } else {
      this.loaderOn = false;
      await this.loadingController.dismiss();
    }
  }
}
