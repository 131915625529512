export class UserProfile {
  id: string;
  displayName: string;
  email: string;
  provider: string;
  createDate: number;
  emailVerified: boolean;
  profileIconLetter: string;
  v1TermsAgreementDate?: number;
  photoURL?: string;
  profilePictureDisabled?: boolean;
  isSubscribed?: boolean;
}
