import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { resolve } from "dns";
import { UserProfile } from "../interfaces/user-profile";

export class SendinblueContactAttributes {
  DISPLAYNAME: string = "";
  PROVIDER: string = "";
}

export class SendinblueContact {
  email: string;
  listIds: number[];
  updateEnabled: boolean = true;
  attributes: SendinblueContactAttributes = new SendinblueContactAttributes();
}

@Injectable({
  providedIn: "root",
})
export class NewsletterService {
  private apiUrl: string = "https://api.sendinblue.com/v3/contacts";
  constructor(public http: HttpClient) {}
  subscribeUserHTTP(user: UserProfile) {
    let contact = new SendinblueContact();
    contact.email = user.email;
    contact.listIds = [3];
    contact.attributes.DISPLAYNAME = user.displayName;
    contact.attributes.PROVIDER = user.provider;

    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "application/json",
        "Content-Type": "application/json",
        "api-key":
          "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",
      }),
    };

    let promise = new Promise((resolve, reject) => {
      this.http
        .post(this.apiUrl, contact, httpOptions)
        .toPromise()
        .then(() => {
          // TODO - log event
          console.log("user subsribed");
          resolve(true);
        })
        .catch((err) => {
          // TODO - log error
          console.log("subscribe error");
          reject(err);
        });
    });

    return promise;
  }
  // subscribeUser(user: UserProfile) {
  //   const SibApiV3Sdk = require("sib-api-v3-typescript");
  //   let apiInstance = new SibApiV3Sdk.ContactsApi();
  //   let apiKey = apiInstance.authentications["apiKey"];
  //   apiKey.apiKey = "YOUR API KEY";

  //   let createContact = new SibApiV3Sdk.CreateContact();
  //   createContact.email = user.email;
  //   createContact.listIds = [3];
  //   createContact.attributes = {
  //     DISPLAYNAME: user.displayName,
  //     PROVIDER: user.provider,
  //   };
  //   apiInstance
  //     .createContact(createContact)
  //     .then((data) => {
  //       console.log(
  //         "API called successfully. Returned data: " + JSON.stringify(data)
  //       );
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // }
}
