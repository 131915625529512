import { PopupEditorPage } from "./../pages/popup-editor/popup-editor.page";
import { Platform, ModalController } from "@ionic/angular";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class LayoutSwitchService {
  public pageTitle = "";
  constructor(
    private platform: Platform,
    private modalController: ModalController
  ) {}

  isMobile() {
    if (this.platform.width() < 768) {
      return true;
    } else {
      return false;
    }
  }

  setpageTitle(title: string) {
    this.pageTitle = title;
  }

  async showPopupEditor(field, id) {
    const modal = await this.modalController.create({
      component: PopupEditorPage,
      cssClass: "help-dialog",
      componentProps: {
        field: field,
        id: id,
      },
    });
    modal.present();
  }
}
