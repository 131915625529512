import { Injectable } from "@angular/core";
import { AngularFireDatabase, AngularFireObject } from "@angular/fire/database";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import {
  LiteSpeech,
  SpeechContext,
  SpeechData,
} from "../interfaces/lite-speech";
import { DataService } from "./data.service";
import { SpeechApiService } from "./speech-api.service";

@Injectable({
  providedIn: "root",
})
export class SpeechDatastoreService {
  private _lastEditedSpeech: BehaviorSubject<LiteSpeech> = new BehaviorSubject(
    null
  );
  public lastEditedSpeech: Observable<LiteSpeech> =
    this._lastEditedSpeech.asObservable();

  private _speechContext: BehaviorSubject<SpeechContext> = new BehaviorSubject(
    null
  );
  public speechContext: Observable<SpeechContext> =
    this._speechContext.asObservable();

  private _speechData: BehaviorSubject<SpeechData> = new BehaviorSubject(null);
  public speechData: Observable<SpeechData> = this._speechData.asObservable();
  public instantSpeechData: SpeechData = null;
  private _speechList: BehaviorSubject<LiteSpeech[]> = new BehaviorSubject([]);
  public speechList: Observable<LiteSpeech[]> = this._speechList.asObservable();

  public editorFooter = {};

  constructor(
    private api: SpeechApiService,
    private data: DataService,
    private db: AngularFireDatabase
  ) {
    // console.log("loading speech-datastore");
    this.loadInitialData();
  }

  loadInitialData(): void {
    const s = this.api
      .loadLastEditedSpeech(this.data.getUid())
      .subscribe((r) => {
        this._lastEditedSpeech.next(r);
        // console.log("last speech updated");
      });
  }

  loadSpeechContext(id: string): void {
    const s = this.api
      .loadSpeechContext(this.data.getUid(), id)
      .subscribe((r) => {
        this._speechContext.next(r);

        // console.log("Context speech loaded", r);
      });
  }

  loadSpeechData(id: string): void {
    const s = this.api.loadSpeechData(this.data.getUid(), id).subscribe((r) => {
      this._speechData.next(r);
      this.instantSpeechData = r;
      // console.log("Speech data loaded", r);
    });
  }

  updateDbData(id: string, field: string, value: string) {
    this.editorFooter[field] = "showLoader";
    const updates = {};

    updates["fullSpeech/" + this.data.getUid() + "/" + id + "/data/" + field] =
      value != "" ? value : null;
    updates["liteSpeech/" + this.data.getUid() + "/" + id + "/editDate"] =
      new Date().getTime();

    // console.log(updates);
    this.api
      .updateSpeechData(updates)
      .then(() => {
        this.editorFooter[field] = "showComplete";
      })
      .then(() => {
        setTimeout(() => {
          this.editorFooter[field] = null;
        }, 2000);
      });
  }

  markSpeechCompleted(id: string) {
    const updates = {};
    updates["liteSpeech/" + this.data.getUid() + "/" + id + "/completed"] =
      true;

    const ref: AngularFireObject<SpeechData> = this.db.object("/");

    return ref.update(updates);
  }

  updateDb(id: string, field: string, value: string) {
    this.editorFooter[field] = "showLoader";
    const updates = {};
    updates[
      "fullSpeech/" + this.data.getUid() + "/" + id + "/context/" + field
    ] = value != "" ? value : null;
    updates["liteSpeech/" + this.data.getUid() + "/" + id + "/editDate"] =
      new Date().getTime();

    this.api
      .updateSpeechData(updates)
      .then(() => {
        this.editorFooter[field] = "showComplete";
      })
      .then(() => {
        setTimeout(() => {
          this.editorFooter[field] = null;
        }, 2000);
      });
  }
}
