import { AuthenticationService } from "./authentication.service";
import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { firestore } from "firebase";
import { DatePipe } from "@angular/common";
import { PopoverController } from "@ionic/angular";
import { PopoverSpeechListPage } from "../pages/popover-speech-list/popover-speech-list.page";
import * as firebase from "firebase/app";

@Injectable({
  providedIn: "root",
})
export class SpeechService {
  public currentSpeech: any = {};

  public speechList = [];
  public deletedSpeechList = [];

  public previousSpeech;
  public speechDates = {};
  public editorFooter = [];
  public upcomingSpeeches = [];
  public upcomingSpeechDates = [];
  private datePipe = new DatePipe("en-US");

  private speechDataRefference;
  constructor(
    private firestore: AngularFirestore,
    public auth: AuthenticationService,
    private popoverController: PopoverController
  ) {
    this.initPreviousSpeech();
    // console.log("init");
  }

  async updateLastEditedSpeech(uid: string, id: string) {
    return await this.firestore
      .collection("/users")
      .doc(uid)
      .set({ lastEdited: id });
  }

  //creates new speech in firestore db
  async createSpeech(speech) {
    speech.createDate = new Date();
    speech.lastEdit = new Date();
    speech.softDelete = false;
    return await this.firestore
      .collection("/users/")
      .doc(await this.auth.getUid())
      .collection("speeches")
      .add(speech);
  }

  //initialize speech data
  async initializeSpeechData(id: string) {
    let uid: string = await this.auth.getUid();
    console.log("initializing speech data", id, uid);

    await this.firestore
      .collection("/users/")
      .doc(uid)
      .collection("speeches")
      .doc(id)
      .collection("speechData")
      .doc("1")
      .set({});

    //set last edited speech
    await this.updateLastEditedSpeech(uid, id);

    await this.loadSpeechList();
    return true;
  }

  async updateField(updates: any, id: string) {
    try {
      let uid = await this.auth.getUid();

      await this.firestore
        .collection("/users")
        .doc(uid)
        .collection("speeches")
        .doc(id)
        .update(updates);
    } catch (error) {
      throw error;
    }
  }

  async permanentDelete(id: string) {
    try {
      let uid = await this.auth.getUid();

      await this.firestore
        .collection("/users")
        .doc(uid)
        .collection("speeches")
        .doc(id)
        .delete();
    } catch (error) {
      throw error;
    }
  }

  async removeDbFields(fields: string[]) {
    try {
      let uid = await this.auth.getUid();
      console.log("removing fields");
      let updates = {};
      fields.forEach((field) => {
        updates[field] = firebase.firestore.FieldValue.delete();
      });

      console.log(updates);

      await this.firestore
        .collection("/users")
        .doc(uid)
        .collection("speeches")
        .doc(this.currentSpeech.id)
        .collection("speechData")
        .doc("1")
        .update(updates);
    } catch (error) {
      throw error;
    }
  }

  async updateDbField(field: string, value: number) {
    if (this.currentSpeech[field] != value) {
      try {
        let uid = await this.auth.getUid();
        console.log("updating values into db", field, value);
        let updates = {};
        updates[field] = value;
        console.log(updates);

        await this.firestore
          .collection("/users")
          .doc(uid)
          .collection("speeches")
          .doc(this.currentSpeech.id)
          .collection("speechData")
          .doc("1")
          .update(updates);

        this.updateCache(updates);
      } catch (error) {
        throw error;
      }
    }
  }

  async updateDb(form, target, field = null, inputName = null) {
    let fieldName = field ? field : target.attributes.formcontrolname.value;
    console.log(fieldName);
    console.log("updating field: ", fieldName, this.currentSpeech.id);

    let formField = inputName ? inputName : fieldName;
    if (this.currentSpeech[fieldName] != form.form.value[formField]) {
      this.editorFooter[fieldName] = {
        showLoader: true,
      };

      try {
        let uid = await this.auth.getUid();
        console.log("updating values into db", form.form.value);
        let updates = {};
        if (inputName) {
          updates[field] = form.form.value[inputName];
        } else {
          updates = form.form.value;
        }
        console.log(updates);
        await this.firestore
          .collection("/users")
          .doc(uid)
          .collection("speeches")
          .doc(this.currentSpeech.id)
          .collection("speechData")
          .doc("1")
          .update(updates);

        this.updateCache(updates);
        // hide loader and show saved message
        this.editorFooter[fieldName].showLoader = false;
        this.editorFooter[fieldName].showComplete = true;
        setTimeout(() => {
          this.editorFooter[fieldName].showComplete = false;
        }, 2000);
      } catch (error) {
        throw error;
      }
    } else {
      console.log("update not needed");
    }
  }

  // loadSpeechData(id: string) {
  //   let uid: string = null

  //   let promise = new Promise((resolve, reject) => {
  //     this.authService.getCurrentUser().then(r => {
  //       uid = r.uid
  //     }).then(() => {
  //       console.log("loading speech data", id, uid)
  //       this.speechDataRefference = this.firestore.collection('/users').doc(uid)
  //         .collection('speeches')
  //         .doc(id).collection('speechData').doc('1')

  //       console.log(this.speechDataRefference)
  //     }).catch(e => reject(e))
  //   })

  //   return promise
  // }

  // async getSpeechDataReff(uid, id) {
  //   return await "s"
  // }

  //load speech from firestore db
  // loadSpeech(id: string) {
  //   let promise = new Promise((resolve, reject) => async () => {
  //     if (this.currentSpeech.id && this.currentSpeech.id == id) {
  //       resolve(this.currentSpeech)
  //     } else {
  //       let uid = await this.auth.getUid()

  //       this.firestore.collection('/users').doc(uid).collection('speeches').doc(id).collection('speechData').doc('1').get().toPromise()
  //         .then(r => {
  //           this.currentSpeech = r.data()
  //           this.currentSpeech.id = id
  //           console.log("loaded speech data from firestore")
  //           return this.currentSpeech
  //         })
  //         .catch(e => {
  //           console.log(e)
  //           throw (e)
  //         })
  //     }
  //   })
  //   if (!this.currentSpeech.id || this.currentSpeech.id != id) {

  //     console.log("getting speech from db")

  //   } else {
  //     console.log("speech already in cache")
  //     return this.currentSpeech
  //   }
  // }

  async loadSpeechAsync(id: string) {
    if (this.currentSpeech.id && this.currentSpeech.id == id) {
      console.log("speech from cache");
      return this.currentSpeech;
    } else {
      console.log("need to load from db");
      try {
        let uid = await this.auth.getUid();
        let speechData = await this.firestore
          .collection("/users")
          .doc(uid)
          .collection("speeches")
          .doc(id)
          .collection("speechData")
          .doc("1")
          .ref.get();
        console.log("got data from db", speechData.data());
        this.currentSpeech = speechData.data();
        this.currentSpeech.id = id;

        console.log(this.currentSpeech);
      } catch (error) {
        throw error;
      }
    }
  }

  // removeFields(id, removeIndex) {
  //   let uid: string = null

  //   this.authService.getCurrentUser().then(r => {
  //     uid = r.uid
  //   }).then(() => {

  //     this.firestore.collection('users').doc(uid).collection('speeches').doc(id).snapshotChanges().pipe(take(1)).subscribe(r => {
  //       let obj = r.payload.data()

  //       let fields = ['subPoint', 'summary', 'supportingDetails']

  //       if (obj['mps'] != removeIndex) {
  //         for (let i = parseInt(removeIndex); i < obj['mps']; i++) {
  //           fields.forEach(item => {
  //             console.log("replacing ", item + i.toString(), item + (i + 1).toString())
  //             obj[item + i.toString()] = obj[item + (i + 1).toString()]
  //           })
  //         }

  //       }

  //       fields.forEach(item => {
  //         delete obj[item + obj['mps']]
  //       })

  //       obj['mps'] = obj['mps'] - 1

  //       console.log('updating object', obj)

  //       this.firestore.collection('users').doc(uid).collection('speeches').doc(id).set(obj)
  //     })
  //   })
  // }

  // loadSpeechDates() {
  //   let uid: string = null
  //   this.previousSpeech = null
  //   let pipe = new DatePipe('en-US')

  //   let promise = new Promise((resolve, reject) => {
  //     this.authService.getCurrentUser().then(r => {
  //       uid = r.uid
  //     }).then(() => {
  //       let refference = this.firestore.collection('/users').doc(uid).collection('speeches').ref

  //       refference.where('softDelete', 'in', [false]).orderBy('dueDate', 'desc').get().then(x => {
  //         this.speechDates = []
  //         x.forEach(speech => {
  //           let speechData = speech.data()

  //           let lightSpeech = []
  //           lightSpeech['id'] = speech.id
  //           lightSpeech['title'] = speechData.title
  //           lightSpeech['dueDate'] = pipe.transform(speechData.dueDate.toDate(), 'dd/MM/yyyy')

  //           if (!(lightSpeech['dueDate'] in this.speechDates)) {
  //             this.speechDates[lightSpeech['dueDate']] = [lightSpeech]
  //           } else {
  //             this.speechDates[lightSpeech['dueDate']].push(lightSpeech)
  //           }
  //         })

  //         console.log(this.speechDates)

  //         resolve(true)

  //       }).catch(e => reject(e))

  //     }).catch(e => reject(e))
  //   })

  //   return promise
  // }

  //update speech details in firestore db
  // updateSpeechData(id, data) {
  //   let uid: string = null

  //   let promise = new Promise((resolve, reject) => {
  //     this.authService.getCurrentUser().then(r => {
  //       uid = r.uid
  //     }).then(() => {

  //       let updates = {}

  //       for (let key in data) {
  //         if (this.currentSpeech[key] != data[key]) {
  //           console.log(key)
  //           updates[key] = data[key]
  //         }
  //       }

  //       updates["lastEdit"] = new Date()

  //       this.firestore.collection('/users').doc(uid)
  //         .collection('speeches').doc(id)
  //         .collection('speechData').doc('1').update(updates)

  //       this.updateRefference(data)

  //     })
  //   })
  // }

  async showSpeechesForDate(date: string, ev) {
    if (date in this.speechDates) {
      const popover = await this.popoverController.create({
        component: PopoverSpeechListPage,
        cssClass: "popover-speech-list",
        event: ev,
        translucent: false,
        showBackdrop: false,
        componentProps: { speeches: this.speechDates[date].slice(1) },
      });
      return await popover.present();
    }
  }

  setPreviousSpeech(speech) {
    console.log("updating previous speech", speech);
    this.previousSpeech = speech;
  }
  // async loadPreviousSpeech() {
  //   console.log("executing load prev speech")
  //   try {
  //     let speechList = await this.firestore.collection('/users').doc(this.auth.user.uid).collection('speeches').ref
  //       .orderBy('lastEdit', 'desc').limit(1).get()
  //     console.log("speech list fetched")

  //     speechList.forEach(speech => {
  //       let tmpSpeech = speech.data()
  //       tmpSpeech.id = speech.id

  //       this.previousSpeech = tmpSpeech

  //       return this.previousSpeech
  //     })
  //   } catch (error) {
  //     throw (error)
  //   }
  // }

  initPreviousSpeech() {
    this.previousSpeech = {
      id: "",
      title: "",
      type: "",
      createDate: "",
      dueDate: "",
      lastEdit: "",
    };
  }

  updateCache(data) {
    console.log(this.currentSpeech);
    for (let key in data) {
      this.currentSpeech[key] = data[key];
    }
  }

  //update speech details in firestore db
  // updateSpeechField(id, field, data) {
  //   let uid: string = null

  //   let promise = new Promise((resolve, reject) => {
  //     this.authService.getCurrentUser().then(r => {
  //       uid = r.uid
  //     }).then(() => {
  //       console.log(id, field, data)
  //       if (this.currentSpeech[field] != data) {
  //         let updates = {}
  //         updates[field] = data
  //         console.log("updating speech", updates)
  //         this.firestore.collection('/users').doc(uid).collection('speeches').doc(id).update(updates)

  //         this.updateRefference(updates)
  //       } else {
  //         console.log("nothing to update")
  //       }
  //     })
  //   })

  // }

  //update local variable with data from form
  // updateRefference(data) {
  //   for (let key in data) {
  //     if (this.currentSpeech[key] != data[key]) {
  //       this.currentSpeech[key] = data[key]
  //     }
  //   }
  // }

  //not used
  // convertToObj(obj: firestore.DocumentSnapshot<firestore.DocumentData>) {
  //   let tmpObj = null

  //   tmpObj = obj.data()
  //   tmpObj.id = obj.id

  //   return tmpObj
  // }

  // speechSoftDelete(id) {
  //   let uid: string = null

  //   let promise = new Promise((resolve, reject) => {
  //     this.authService.getCurrentUser().then(r => {
  //       uid = r.uid
  //     }).then(() => {

  //       let updates = {}
  //       updates['softDelete'] = true
  //       updates['softDeleteDate'] = new Date()

  //       this.firestore.collection('/users').doc(uid).collection('speeches').doc(id).update(updates).then(() => {
  //         this.loadSpeechList().then(() => {
  //           resolve(true)
  //         }).catch(e => reject('reload_list_error'))
  //       }).catch(e => reject('delete_error'))
  //     }).catch(e => reject('get_user_error'))
  //   })

  //   return promise
  // }

  async loadDeletedSpeechList() {
    let deletedSpeechList = [];
    let speech = null;
    try {
      let speeches = await this.firestore
        .collection("/users/")
        .doc(await this.auth.getUid())
        .collection("speeches")
        .ref.where("softDelete", "==", true)
        .orderBy("lastEdit", "desc")
        .get();
      speeches.forEach((result) => {
        speech = result.data();
        speech.id = result.id;

        deletedSpeechList.push(speech);
      });

      this.deletedSpeechList = deletedSpeechList;
    } catch (error) {
      throw error;
    }
  }

  async loadUpcomingSpeeches() {
    console.log("load upcoming speeches");
    try {
      let speeches = await this.firestore
        .collection("/users/")
        .doc(await this.auth.getUid())
        .collection("speeches")
        .ref.where("softDelete", "==", false)
        .where("dueDate", ">=", firestore.Timestamp.fromDate(new Date()))
        .orderBy("dueDate", "asc")
        .limit(3)
        .get();

      this.upcomingSpeeches = [];
      this.upcomingSpeechDates = [];
      speeches.forEach((speech) => {
        let date = this.datePipe.transform(
          speech.data().dueDate.toDate(),
          "MMMM d, y"
        );
        if (!(date in this.upcomingSpeeches)) {
          this.upcomingSpeeches[date] = [];
        }
        this.upcomingSpeeches[date].push(speech.data());
      });

      console.log(this.upcomingSpeeches);
    } catch (error) {
      throw error;
    }
  }

  async loadSpeechList() {
    console.log("getting speeches", this.auth.user.uid);
    let speechList = [];
    let speech = null;
    let speechDates = [];
    let pipe = new DatePipe("en-US");

    try {
      let speeches = await this.firestore
        .collection("/users/")
        .doc(await this.auth.getUid())
        .collection("speeches")
        .ref.where("softDelete", "==", false)
        .orderBy("lastEdit", "desc")
        .get();

      speeches.forEach((result) => {
        speech = result.data();
        speech.id = result.id;

        console.log(speech);

        speechList.push(speech);

        if (speech.dueDate) {
          let lightSpeech = {};
          lightSpeech["id"] = speech.id;
          lightSpeech["title"] = speech.title;
          lightSpeech["type"] = speech.type;
          lightSpeech["dueDate"] = pipe.transform(
            speech.dueDate.toDate(),
            "dd/MM/yyyy"
          );

          if (!(lightSpeech["dueDate"] in speechDates)) {
            speechDates[lightSpeech["dueDate"]] = [
              { hasInformative: false, hasPersuasive: false },
            ];
          }

          speechDates[lightSpeech["dueDate"]].push(lightSpeech);

          if (lightSpeech["type"] == "persuasive") {
            speechDates[lightSpeech["dueDate"]][0].hasPersuasive = true;
          }
          if (lightSpeech["type"] == "informative") {
            speechDates[lightSpeech["dueDate"]][0].hasInformative = true;
          }
        }
      });

      this.speechList = speechList;
      this.speechDates = speechDates;
      if (this.speechList.length) {
        this.setPreviousSpeech(this.speechList[0]);
      } else {
        this.previousSpeech = null;
      }

      console.log(this.speechList);
      console.log(this.speechDates);
    } catch (error) {
      throw error;
    }
    // let promise = new Promise((resolve, reject) => {
    //   this.authService.getCurrentUser().then(r => {
    //     uid = r.uid
    //   }).then(() => {

    //     let speechList = []
    //     let speech = null

    //     this.firestore.collection('/users/').doc(uid).collection('speeches').ref.where('softDelete', '==', false).orderBy('lastEdit', 'desc').get().then(snap => {
    //       snap.forEach(e => {
    //         speech = e.data()
    //         speech.id = e.id

    //         speechList.push(speech)
    //       })

    //       this.speechList = speechList
    //       resolve(true)
    //     })

    //   })
    // })

    // return promise
  }
  // loadDeletedSpeechList() {
  //   let uid: string = null

  //   let promise = new Promise((resolve, reject) => {
  //     this.authService.getCurrentUser().then(r => {
  //       uid = r.uid
  //     }).then(() => {
  //       console.log("getting speeches", uid)

  //       let deletedSpeechList = []
  //       let speech = null

  //       this.firestore.collection('/users/').doc(uid).collection('speeches').ref.where('softDelete', '==', true).orderBy('softDeleteDate', 'desc').get().then(snap => {
  //         snap.forEach(e => {
  //           speech = e.data()
  //           speech.id = e.id

  //           deletedSpeechList.push(speech)
  //         })

  //         this.deletedSpeechList = deletedSpeechList
  //         resolve(true)
  //       }).catch(e => reject('load_list_error'))

  //     })
  //   })

  //   return promise
  // }
}
