import { Injectable } from "@angular/core";
import {
  AngularFireDatabase,
  AngularFireObject,
  AngularFireList,
} from "@angular/fire/database";
import { Observable } from "rxjs";
import {
  LiteSpeech,
  SpeechContext,
  SpeechData,
} from "../interfaces/lite-speech";

@Injectable({
  providedIn: "root",
})
export class SpeechApiService {
  constructor(private db: AngularFireDatabase) {}

  loadLastEditedSpeech(uid: string): Observable<LiteSpeech> {
    const ref: AngularFireObject<LiteSpeech> = this.db.object(
      "lastEditedSpeech/" + uid
    );

    return ref.valueChanges();
  }

  loadSpeechContext(uid: string, id: string): Observable<SpeechContext> {
    const ref: AngularFireObject<SpeechContext> = this.db.object(
      "fullSpeech/" + uid + "/" + id + "/context"
    );

    return ref.valueChanges();
  }

  loadSpeechData(uid: string, id: string): Observable<SpeechData> {
    const ref: AngularFireObject<SpeechData> = this.db.object(
      "fullSpeech/" + uid + "/" + id + "/data"
    );

    return ref.valueChanges();
  }

  updateSpeechData(updates: Partial<SpeechData>) {
    const ref: AngularFireObject<SpeechData> = this.db.object("/");

    return ref.update(updates);
  }

  getSpeechList(uid: string) {
    const ref: AngularFireList<LiteSpeech> = this.db.list("liteSpeech/" + uid);

    return ref;
  }

  deleteSpeech(uid: string, id: string) {
    const ref: AngularFireObject<LiteSpeech> = this.db.object(
      "liteSpeech/" + uid + "/" + id
    );

    ref
      .snapshotChanges()
      .toPromise()
      .then((r) => {
        console.log(r);
      });
  }
}
