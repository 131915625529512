import { LoggingService } from "./logging.service";
import { Injectable, Injector } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { UserProfile } from "../interfaces/user-profile";
import { take } from "rxjs/operators";
import {
  FullSpeech,
  LiteSpeech,
  SpeechContext,
} from "../interfaces/lite-speech";
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from "@angular/fire/database";
import { UiService } from "./ui.service";
import { DatePipe } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class DataService {
  private userProfileSource: BehaviorSubject<UserProfile> = new BehaviorSubject(
    null
  );
  public userProfile = this.userProfileSource.asObservable();

  private showAppLoaderSource: BehaviorSubject<boolean> = new BehaviorSubject(
    true
  );
  public showAppLoader = this.showAppLoaderSource.asObservable();

  public uid: string = null;

  private speechByDateSource: BehaviorSubject<any> = new BehaviorSubject([]);
  public speechByDate = this.speechByDateSource.asObservable();

  private upcomingSpeechesSource: BehaviorSubject<any> = new BehaviorSubject(
    null
  );
  public upcomingSpeeches = this.upcomingSpeechesSource.asObservable();

  public lastEditedSpeech: Observable<LiteSpeech>;
  public liteSpeechList: Observable<LiteSpeech[]>;
  public trashList: Observable<LiteSpeech[]>;

  constructor(
    private db: AngularFireDatabase,
    private log: LoggingService,
    private ui: UiService,
    private injector: Injector
  ) {
    // console.log("load data service");
  }

  public setUserProfile(profile: UserProfile) {
    this.userProfileSource.next(profile);
  }

  public setAppLoader(val: boolean) {
    this.showAppLoaderSource.next(val);
  }

  public setUid(uid: string) {
    // console.log("got uid", uid);
    this.uid = uid;
    this.initObservables();
  }

  public getLastEditedSpeech(): Observable<LiteSpeech> {
    const lastEditedSpeechRef: AngularFireObject<LiteSpeech> = this.db.object(
      "lastEditedSpeech/" + this.uid
    );
    return lastEditedSpeechRef.valueChanges();
  }

  public getSpeechContext(id: string): Observable<SpeechContext> {
    const ref: AngularFireObject<SpeechContext> = this.db.object(
      "fullSpeech/" + this.uid + "/" + id + "/context"
    );
    return ref.valueChanges();
  }

  private initObservables() {
    // console.log("initializing observables ", this.uid);
    const lastEditedSpeechRef: AngularFireObject<LiteSpeech> = this.db.object(
      "lastEditedSpeech/" + this.uid
    );

    this.lastEditedSpeech = lastEditedSpeechRef.valueChanges();

    this.db
      .object("users/" + this.uid + "/profile")
      .valueChanges()
      .subscribe((r: UserProfile) => {
        this.setUserProfile(r);
      });

    const speechListRef: AngularFireList<LiteSpeech> = this.db.list(
      "liteSpeech/" + this.uid,
      (ref) => ref.orderByChild("softDelete").equalTo(false)
    );
    this.liteSpeechList = speechListRef.valueChanges();
    let pipe = new DatePipe("en-US");
    this.liteSpeechList.subscribe((r) => {
      if (r.length) {
        let skip: boolean;
        let upcoming = [];
        r.forEach((speech) => {
          if (speech.dueDate) {
            let shortDate = pipe.transform(speech.dueDate, "dd/MM/yyyy");

            if (speech.dueDate > new Date().getTime()) {
              if (!(speech.dueDate in upcoming)) upcoming[speech.dueDate] = [];

              upcoming[speech.dueDate].push(speech);
            }

            if (!(shortDate in this.speechByDate)) {
              this.speechByDate[shortDate] = [
                { hasInformative: false, hasPersuasive: false },
              ];
            }

            if (speech.type == "persuasive")
              this.speechByDate[shortDate][0].hasPersuasive = true;

            if (speech.type == "informative")
              this.speechByDate[shortDate][0].hasInformative = true;

            skip = false;
            this.speechByDate[shortDate].slice(1).forEach((element) => {
              if (element.id == speech.id) {
                skip = true;
              }
            });

            if (!skip) {
              this.speechByDate[shortDate].push(speech);
            }

            this.speechByDateSource.next(this.speechByDate);
          }
        });

        this.upcomingSpeechesSource.next(upcoming);
        // console.log(upcoming);
      }
    });
  }

  public getPushId(): string {
    return this.db.createPushId();
  }

  public getUid(): string {
    return this.uid;
  }

  public setLastEditedSpeech(val) {
    this.lastEditedSpeech = val;
  }

  public editSpeechData(field: string, value: string, id: string) {
    const ref: AngularFireObject<LiteSpeech> = this.db.object(
      "/lastEditedSpeech/" + this.uid
    );
    ref
      .valueChanges()
      .pipe(take(1))
      .subscribe((r) => {
        if (r.id == id) {
          console.log("updating lastSpeech");
          let lastSpeechUpdates = {};
          lastSpeechUpdates["lastEditedSpeech/" + this.uid + "/" + field] =
            value;
          this.db.object("/").update(lastSpeechUpdates);
        }
      });
    let updates = {};
    updates["liteSpeech/" + this.uid + "/" + id + "/" + field] = value;

    return this.db.object("/").update(updates);
  }

  public loadTrashList() {
    // console.log("loading trash list");

    const trashListRef: AngularFireList<LiteSpeech> = this.db.list(
      "liteSpeech/" + this.uid,
      (ref) => ref.orderByChild("softDelete").equalTo(true)
    );

    this.trashList = trashListRef.valueChanges();
  }

  restoreSpeech(id: string) {
    this.ui.presentLoading("Restoring speech ...");
    let updates = {};
    updates["liteSpeech/" + this.uid + "/" + id + "/softDelete"] = false;
    this.db
      .object("/")
      .update(updates)
      .then(() => {
        this.ui.showToast("Speech restored to Library.");
        this.log.remote("INFO", "event-speech-restored");
      })
      .catch((err) => {
        this.log.remote("ERROR", "error-restore-speech", err.message);
      })
      .finally(() => {
        this.ui.hideLoading();
      });
  }

  //speeches
  deleteSpeech(id: string) {
    let updates = {};
    updates["liteSpeech/" + this.uid + "/" + id + "/softDelete"] = true;
    return this.db.object("/").update(updates);
  }

  purgeSpeechData(id: string) {
    let updates = {};
    updates["liteSpeech/" + this.uid + "/" + id] = null;
    updates["fullSpeech/" + this.uid + "/" + id] = null;
    return this.db.object("/").update(updates);
  }

  createSpeech(speech: LiteSpeech) {
    let updates = {};

    updates["/liteSpeech/" + this.uid + "/" + speech.id] = speech;
    updates["/lastEditedSpeech/" + this.uid] = speech;
    updates["/fullSpeech/" + this.uid + "/" + speech.id] = new FullSpeech(
      speech.id,
      speech.type
    );

    const promise = new Promise((resolve, reject) => {
      this.db.database
        .ref()
        .update(updates)
        .then(() => {
          resolve(speech.id);
        })
        .catch((e) => {
          reject(e);
        });
    });

    return promise;
  }
}
