import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Platform, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-popover-speech-list',
  templateUrl: './popover-speech-list.page.html',
  styleUrls: ['./popover-speech-list.page.scss'],
})
export class PopoverSpeechListPage implements OnInit {
  @Input() speeches;
  constructor(
    private platform: Platform,
    private router: Router,
    private popoverController: PopoverController
  ) { }

  ngOnInit() {
  }

  navigateToSpeech(type: string, id: string) {
    this.popoverController.dismiss()
    if (this.platform.is('ios') || this.platform.is('android')) {
      this.router.navigate(['/pages/composer/mobile-index/' + type + '/' + id])
    } else {
      this.router.navigate(['/pages/composer/context-guide/' + type + '/' + id])
    }
  }
}
