import { Injectable } from "@angular/core";
import { AngularFireDatabase, AngularFireList } from "@angular/fire/database";

export enum SubscriptionType {
  basic = "basic",
}

export enum SubscriptionStatus {
  freeTrialActive = "freeTrialActive",
  freeTrialExpired = "freeTrialExpired",
  active = "active",
  incactive = "inactive",
}

export enum FreeTrialClass {
  D180 = "D180",
}

export class UserSubscription {
  id: string;
  uid: string;
  type: SubscriptionType;
  startDate: number;
  status: SubscriptionStatus;
  freeTrialClass: FreeTrialClass;

  constructor(
    uid: string,
    type: SubscriptionType,
    startDate: number,
    status: SubscriptionStatus,
    freeTrialClass: FreeTrialClass
  ) {
    this.uid = uid;
    this.type = type;
    this.startDate = startDate;
    this.status = status;
    this.freeTrialClass = freeTrialClass;
  }
}

@Injectable({
  providedIn: "root",
})
export class SubscriptionService {
  constructor(private db: AngularFireDatabase) {}

  createSubscription(subscription: UserSubscription) {
    const subscriptionListReff: AngularFireList<UserSubscription> =
      this.db.list("subscriptions/" + subscription.uid);

    subscriptionListReff.push(subscription);
  }
}
