export class LiteSpeech {
  id: string;
  type: string;
  title: string;
  dueDate: number;
  progress: number;
  excerpt: string;
  createDate: number;
  editDate: number;
  softDelete: boolean;
  completed: boolean = false;

  constructor(type: string, title: string, dueDate: number) {
    this.type = type;
    this.title = title;
    this.dueDate = dueDate;
    this.softDelete = false;
  }

  setId(val: string) {
    this.id = val;
  }

  setProgress(val: number) {
    this.progress = val;
  }

  setExcerpt(val: string) {
    this.excerpt = val;
  }

  setCreateDate(val: number) {
    this.createDate = val;
  }

  setEditDate(val: number) {
    this.editDate = val;
  }
}

export class CalendarSpeech {
  id: string;
  type: string;
  title: string;
  dueDate: number;
  softDelete: boolean;

  constructor(speech: LiteSpeech) {
    this.id = speech.id;
    this.type = speech.type;
    this.title = speech.title;
    this.dueDate = speech.dueDate;
    this.softDelete = speech.softDelete;
  }
}

export class SpeechContext {
  id: string;
  audienceWho: string;
  audienceKnowledge: string;
  audienceExpectations: string;
  goalInformation: string;
  goalEmotion: string;
  goalAction: string;
  coreIdea: string;
  coreResult: string;
}

export class SpeechData {
  id: string;
  openingStatement: string;
  reasonToListen: string;
  credibilityStatement: string;
  thesisStatement: string;
  previewOfMainPoints: string;
  subPoint1: string;
  supportingDetails1: string;
  summaryAndTransition1: string;
  subPoint2: string;
  supportingDetails2: string;
  summaryAndTransition2: string;
  subPoint3: string;
  supportingDetails3: string;
  summaryAndTransition3: string;
  signalTheEnd: string;
  reviewOfMainPoints: string;
  keyTakeaway: string;
  closingStatement: string;
}
export class FullSpeech {
  id: string;
  type: string;

  constructor(id: string, type: string) {
    this.id = id;
    this.type = type;
  }
}
