import { DataService } from "src/app/services/data.service";
import { LayoutSwitchService } from "src/app/services/layout-switch.service";
import { SpeechActionsMenuPage } from "./../speech-actions-menu/speech-actions-menu.page";
import { Component, Inject, OnInit } from "@angular/core";
import {
  AlertController,
  LoadingController,
  Platform,
  PopoverController,
} from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { LiteSpeech } from "src/app/interfaces/lite-speech";
import { LoggingService } from "src/app/services/logging.service";
import { UiService } from "src/app/services/ui.service";
// import { EditSpeechDialogPage } from "../edit-speech-dialog/edit-speech-dialog.page";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-speech-library",
  templateUrl: "./speech-library.page.html",
  styleUrls: ["./speech-library.page.scss"],
})
export class SpeechLibraryPage implements OnInit {
  public showFilters: boolean = false;
  public speechLength = { persuasive: 0.25, informative: 0.26 };
  constructor(
    public data: DataService,
    public popoverController: PopoverController,
    public platform: Platform,
    public pageLayout: LayoutSwitchService,
    public t: TranslateService,
    private loadingController: LoadingController,
    public dialog: MatDialog
  ) {}

  ngOnInit() {}

  async presentLoading() {
    const loading = await this.loadingController.create({
      cssClass: "my-custom-class",
      message: "Loading speeches ...",
    });
    await loading.present();
  }

  ionViewWillEnter() {}

  toggleFiltersButtonClicked() {
    this.showFilters = !this.showFilters;
  }

  async showContextMenu(ev: any, speech) {
    const popover = await this.popoverController.create({
      component: SpeechActionsMenuPage,
      cssClass: "speech-actions-menu",
      event: ev,
      showBackdrop: true,
      translucent: false,
      componentProps: {
        page: "speech-library",
        speech: speech,
      },
    });
    return await popover.present();
  }

  showDeleteSpeechDialog(speech: LiteSpeech) {
    const dialogRef = this.dialog.open(DeleteSpeechDialog, {
      data: {
        speech: speech,
        action: "delete",
      },
    });
  }

  deleteSpeechV2(speech: LiteSpeech) {
    this.showDeleteSpeechDialog(speech);
  }

  changeSpeechV2(speech: LiteSpeech, action: string) {
    const dialogRef = this.dialog.open(UpdateSpeechDialog, {
      data: { speech: speech, action: action },
    });
  }

  // changeSpeech(speech: LiteSpeech, action: string) {
  //   const dialogRef = this.dialog.open(EditSpeechDialogPage, {
  //     data: {
  //       speech: speech,
  //       action: action,
  //     },
  //   });
  // }
}

@Component({
  selector: "update-speech-dialog",
  templateUrl: "update-speech-dialog.html",
})
export class UpdateSpeechDialog {
  public form: FormGroup;
  public minDate: Date;
  public showLoader: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<UpdateSpeechDialog>,
    private builder: FormBuilder,
    public ds: DataService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.form = this.builder.group({
      speechTitle: ["", Validators.required],
      dueDate: [""],
    });

    this.minDate = new Date();
  }

  ngOnInit() {
    this.form.patchValue({ speechTitle: this.data.speech.title });

    if (this.data.speech.dueDate) {
      this.form.patchValue({ dueDate: new Date(this.data.speech.dueDate) });
    }
    console.log(new Date(this.data.speech.dueDate));
  }

  async updateSpeech() {
    this.showLoader = true;
    let field = null;
    let value = null;
    if (this.data.action == "rename") {
      field = "title";
      value = this.form.value["speechTitle"];
    }
    if (this.data.action == "reschedule") {
      field = "dueDate";
      value = this.form.value["dueDate"];
    }

    this.ds.editSpeechData(field, value, this.data.speech.id).finally(() => {
      this.showLoader = false;
      this.dialogRef.close();
    });
  }
}

@Component({
  selector: "delete-speech-dialog",
  templateUrl: "delete-speech-dialog.html",
})
export class DeleteSpeechDialog {
  public showLoader: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public ds: DataService,
    public dialogRef: MatDialogRef<DeleteSpeechDialog>,
    private log: LoggingService,
    private ui: UiService
  ) {}

  deleteSpeech(id: string) {
    this.showLoader = true;
    this.ds
      .deleteSpeech(id)
      .then(() => {
        this.log.remote("INFO", "event-speech-deleted");
        this.showLoader = false;
        this.dialogRef.close();
        this.ui.showToast("Speech moved to Trash.");
      })
      .catch((err) => {
        this.dialogRef.close();
        this.log.remote("ERROR", "error-delete-speech", err.message);
      });
  }

  purgeSpeechData(id: string) {
    this.showLoader = true;
    this.ds.purgeSpeechData(id).finally(() => {
      this.showLoader = false;
      this.dialogRef.close();
    });
  }
}
