import { DataService } from "src/app/services/data.service";
import { SpeechApiService } from "./../../services/speech-api.service";
import { ApiService } from "./../../services/api.service";
import { EditSpeechDialogPage } from "./../edit-speech-dialog/edit-speech-dialog.page";
import {
  AlertController,
  ModalController,
  PopoverController,
} from "@ionic/angular";
import { SpeechService } from "src/app/services/speech.service";
import { Component, OnInit, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-speech-actions-menu",
  templateUrl: "./speech-actions-menu.page.html",
  styleUrls: ["./speech-actions-menu.page.scss"],
})
export class SpeechActionsMenuPage implements OnInit {
  public page: string;
  @Input() speech;
  constructor(
    private ss: SpeechService,
    private modalController: ModalController,
    private popup: PopoverController,
    private alertController: AlertController,
    private userApi: ApiService,
    private speechApi: SpeechApiService,
    public t: TranslateService,
    private data: DataService
  ) {}

  ngOnInit() {}

  async changeSpeech(speech, action: string) {
    this.popup.dismiss();
    console.log(speech);
    const modal = await this.modalController.create({
      component: EditSpeechDialogPage,
      cssClass: "edit-speech-dialog",
      componentProps: {
        speech: speech,
        action: action,
      },
    });
    modal.present();
    const { data } = await modal.onDidDismiss();
  }

  async deleteSpeech(speech) {
    this.popup.dismiss();
    console.log(speech);
    const alert = await this.alertController.create({
      cssClass: "delete-speech-alert",
      header: this.t.instant("speech-actions-menu.deleteAlertTitle"),
      message:
        this.t.instant("speech-actions-menu.deleteAlertQuestion") +
        speech.title,
      buttons: [
        {
          text: this.t.instant("speech-actions-menu.btn.cancel"),
          role: "cancel",
        },
        {
          text: this.t.instant("speech-actions-menu.btn.delete"),
          handler: async () => {
            this.data.deleteSpeech(speech.id);
          },
        },
      ],
    });

    await alert.present();
  }
}
