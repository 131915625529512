import { Injectable } from "@angular/core";
// import * as Editor from "@anvibo/ckeditor5-build-balloon-block";
import * as Editor from "@bfrunza/ckeditor5-build-classic";
// import * as Editor from "@ckeditor/ckeditor5-build-balloon";
// import * as Editor from "@ckeditor/ckeditor5-build-classic";
@Injectable({
  providedIn: "root",
})
export class EditorService {
  public editor = Editor;
  public activeEditor: string = "";
  constructor() {
    // console.log("editor initialized")
  }

  setActiveEditor(field: string) {
    this.activeEditor = field;
  }

  clearActiveEditor() {
    this.activeEditor = "";
  }
}
