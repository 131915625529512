import { AuthUser } from "./../interfaces/auth-user";
import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { auth } from "firebase/app";
import { ToastController } from "@ionic/angular";
import { UserDetails } from "../interfaces/user-details";
import { UserProfile } from "../interfaces/user-profile";
import { UserService } from "./user.service";
import { ReportingService } from "./reporting.service";
import { NewsletterService } from "./newsletter.service";
import { take } from "rxjs/operators";
@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  public user = null;
  public userData: UserDetails;
  constructor(
    public ngFireAuth: AngularFireAuth,
    private router: Router,
    private toastController: ToastController,
    private userService: UserService,
    private r: ReportingService,
    public newsletterService: NewsletterService
  ) {
    this.ngFireAuth.authState.subscribe((user) => {
      if (user && user.uid) {
        // console.log("user logged in ", user.emailVerified);
        this.user = user;
      } else {
        this.user = null;
        console.log("user not logged in");
        // this.router.navigate(['/auth/login'])
      }
    });
  }

  isLoaded(): boolean {
    if (this.user != null) return true;
    else return false;
  }

  getUser() {
    let promise = new Promise<AuthUser>((resolve, reject) => {
      if (this.user.uid != "") {
        console.log("serving user from cache");
        resolve(this.user);
      } else {
        console.log("serving user from db");

        this.ngFireAuth.currentUser
          .then((user) => {
            resolve(user);
          })
          .catch((e) => {
            reject(e);
          });
      }
    });

    return promise;
  }

  async getUserAsync(): Promise<AuthUser> {
    if (this.user && this.user.uid != "") {
      console.log("serving user from cache");
      return this.user;
    } else {
      console.log("serving user from db");
      try {
        let user = await this.ngFireAuth.currentUser;
        this.user = user;
        console.log("user fetched from db");
        return user;
      } catch (error) {
        throw error;
      }
    }
  }

  async reloadUser() {
    try {
      let user = await this.ngFireAuth.currentUser;
      this.user = user;
    } catch (error) {
      throw error;
    }
  }

  async getUid() {
    if (this.user.uid != "") {
      console.log("serve uid from cache");
      return this.user.uid;
    } else {
      console.log("need uid from db");
      try {
        await this.getUserAsync();
        console.log("serve uid from db");
        return this.user.uid;
      } catch (error) {
        throw error;
      }
    }
  }
  // Sign in with Gmail
  async googleSignIn() {
    let isNew: boolean = false;
    this.ngFireAuth
      .signInWithPopup(new auth.GoogleAuthProvider())
      .then((result) => {
        const profile = new UserProfile();
        if (result.additionalUserInfo.isNewUser) {
          profile.createDate = new Date().getTime();
          profile.emailVerified = true;
          profile.provider = "google";
          profile.email = result.user.email;
          isNew = true;
          profile.isSubscribed = false;
        }

        profile.displayName = result.user.displayName;
        profile.id = result.user.uid;
        profile.photoURL = result.user.photoURL;

        this.userService
          .updateProfile(profile, isNew)
          .then(() => {
            // create email subscription
            let userProfile = this.userService.loadProfile(profile.id);
            userProfile
              .valueChanges()
              .pipe(take(1))
              .subscribe((r) => {
                if (!r.isSubscribed) {
                  // TODO - log event
                  this.newsletterService.subscribeUserHTTP(r).then(() => {
                    this.userService
                      .setFields(new Map([["isSubscribed", true]]))
                      .catch((err) => {
                        console.log(err);
                        // TODO - log error
                      });
                  });
                }
              });
            this.router.navigate(["/pages/dashboard"]);
          })
          .catch((e) => {
            this.r.error(e, "authentication_service_1");
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // Sign in with Facebook
  async facebookSignIn() {
    this.ngFireAuth
      .signInWithPopup(new auth.FacebookAuthProvider())
      .then((result) => {});
  }

  // Register user
  registerUser(email, password) {
    return this.ngFireAuth.createUserWithEmailAndPassword(email, password);
  }

  async presentToast(message: string, cssClass: string = "") {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
      cssClass: cssClass,
    });
    toast.present();
  }

  async updateDisplayName(displayName: string) {
    try {
      return await this.user.updateProfile({ displayName: displayName });
    } catch (error) {
      throw error;
    }
  }

  resetPassword(email: string) {
    return this.ngFireAuth.sendPasswordResetEmail(email);
  }

  checkPasswordResetCode(code: string) {
    return this.ngFireAuth.verifyPasswordResetCode(code);
  }

  verifyEmail(code: string) {
    return this.ngFireAuth.applyActionCode(code);
  }

  checkActionCode(code: string) {
    return this.ngFireAuth.checkActionCode(code);
  }

  async updateUserPassword(currentPassword: string, password: string) {
    try {
      await this.ngFireAuth.signInWithEmailAndPassword(
        this.user.email,
        currentPassword
      );
      return await this.user.updatePassword(password);
    } catch (error) {
      throw error;
    }
    // this.ngFireAuth.signInWithEmailAndPassword(this.user.email, currentPassword).then(() => {
    //   this.user.updatePassword(password).then(() => {
    //     this.presentToast("Password updated")
    //   }).catch(() => {
    //     this.presentToast("Password update error", "toast-error")
    //   })
    // }).catch(() => {
    //   this.presentToast("Password update failed due to authentication error", "toast-error")
    // })
  }

  updatePassword(code: string, password: string) {
    return this.ngFireAuth.confirmPasswordReset(code, password);
  }

  loginUser(email: string, password: string) {
    this.ngFireAuth.fetchSignInMethodsForEmail(email).then((r) => {
      console.log(r);
    });
    return this.ngFireAuth.signInWithEmailAndPassword(email, password);
  }

  signOut() {
    return this.ngFireAuth.signOut();
  }

  // storeUserData(user: firebase.User) {
  //   const userData: User = {
  //     uid: user.uid,
  //     email: user.email,
  //     displayName: user.displayName,
  //     photoURL: user.photoURL,
  //     emailVerified: user.emailVerified,
  //     provider: user.providerId
  //   }

  //   console.log(userData)
  // }
}
