import { LoaderService } from "./services/loader.service";
import { Component } from "@angular/core";

import { Platform } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translate: TranslateService,
    public loader: LoaderService
  ) {
    this.initializeApp();
    this.translate.addLangs(["en"]);
    this.translate.setDefaultLang("en");
    this.translate
      .use("en")
      .toPromise()
      .then(() => {
        this.loader.deactivate();
      });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (!this.platform.is("desktop")) {
        this.statusBar.styleDefault();
        this.splashScreen.hide();
      }
    });
  }

  ngOnInit() {
    // console.log("loading app");
  }
}
