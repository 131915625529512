import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PopoverSpeechListPage } from './popover-speech-list.page';

const routes: Routes = [
  {
    path: '',
    component: PopoverSpeechListPage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PopoverSpeechListPageRoutingModule {}
