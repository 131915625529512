import { SpeechService } from "./../../services/speech.service";
import { Component, Inject, Input, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { ModalController } from "@ionic/angular";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DataService } from "src/app/services/data.service";

@Component({
  selector: "app-edit-speech-dialog",
  templateUrl: "./edit-speech-dialog.page.html",
  styleUrls: ["./edit-speech-dialog.page.scss"],
})
export class EditSpeechDialogPage implements OnInit {
  public form: FormGroup;
  @Input() speech;
  @Input() action;
  public minDate: Date;
  constructor(
    public dialogRef: MatDialogRef<EditSpeechDialogPage>,
    private builder: FormBuilder,
    public modalController: ModalController,
    private ss: SpeechService,
    private ds: DataService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.form = this.builder.group({
      speechTitle: ["", Validators.required],
      dueDate: [""],
    });

    this.minDate = new Date();
  }

  ngOnInit() {
    this.form.patchValue({ speechTitle: this.data.speech.title });

    if (this.data.speech.dueDate) {
      this.form.patchValue({ dueDate: new Date(this.data.speech.dueDate) });
    }
    console.log(new Date(this.data.speech.dueDate));
  }

  cancelButtonClicked() {
    this.dialogRef.close();
  }

  async saveButtonClicked() {
    let field = null;
    let value = null;
    if (this.data.action == "change-title") {
      field = "title";
      value = this.form.value["speechTitle"];
    }
    if (this.data.action == "change-due-date") {
      field = "dueDate";
      value = this.form.value["dueDate"];
    }

    this.ds.editSpeechData(field, value, this.data.speech.id);

    this.dialogRef.close();
  }
}
