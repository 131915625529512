import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Injectable({
  providedIn: 'root'
})
export class ReportingService {

  constructor(
    private analytics: AngularFireAnalytics
  ) { }

  error(message, error: string = null) {

    console.log(message)

    if (error != null) {
      this.analytics.logEvent(error, { msg: message })
    }

  }

  log(msg: string) {
    console.log(msg)
  }
}
