import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { rejects } from 'assert';
import { promise } from 'protractor';
import { UserProfile } from '../interfaces/user-profile';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private afAuth: AngularFireAuth,
    private userService: UserService
  ) { }

  getUserProfile(uid: string): Promise<UserProfile> {
    let promise = new Promise<UserProfile>((resolve, reject) => {
      this.userService.loadProfile(uid).valueChanges().subscribe(data => {
        resolve(data)
      })

    })

    return promise
  }

  getAuthUid(): Promise<string> {
    const promise = new Promise<string>((resolve, reject) => {
      this.afAuth.currentUser.then(user => {
        resolve(user.uid)
      }).catch(e => {
        reject(e)
      })
    })

    return promise
  }
}
