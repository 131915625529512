import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class LoaderService {
  public hide: boolean = false;
  public show: boolean = true;
  constructor() {}

  hideLoader() {
    this.hide = true;
  }

  deactivate() {
    this.show = false;
  }

  activate() {
    this.show = true;
  }
}
