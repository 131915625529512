import { AngularFireFunctions } from "@angular/fire/functions";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class LoggingService {
  constructor(private functions: AngularFireFunctions) {}

  debug(obj: any): void {
    console.log(obj);
  }

  error(obj: any, label: string = null): void {
    console.log(label, obj);
  }

  event(str: string): void {
    console.log(str);
  }

  remote(level: string, code: string, msg: string = null) {
    // {
    //   "appVersion": "1.0.0",
    //   "level": "INFO|ERROR",
    //   "message": "<long string>"
    //   "code"
    // }
    let entry = {};
    entry["appVersion"] = "0.0.1";
    entry["level"] = level;
    entry["code"] = code;
    entry["message"] = msg;
    const call = this.functions.httpsCallable("httpLogger");
    call(entry)
      .toPromise()
      .catch((_) => {});
  }
}
